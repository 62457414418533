<template>
  <div class="card">
    <div class="card-header"><strong>Posted by Creselda Jane A. Ebba:    </strong></div>
    <div class="card-body">
      <div class="author-image">
        <img
          src="/assets/images/author/cres.png"
          alt="Image"
          class="rounded-circle" />
      </div>
      <p>
        <i>
          <a href="https://www.linkedin.com/in/cres-jane-ebba-mba-8446bb242/">Creselda</a>
          is a seasoned HR professional with extensive experience in recruitment, talent acquisition, and organizational development. As the HR Manager at eFlexervices, she manages end-to-end recruitment, employee relations, and engagement activities while ensuring adherence to employment laws. Creselda holds an MBA and is certified in Lean Six Sigma (Yellow Belt), with additional expertise in instructional design and talent sourcing. She has a strong background in leveraging tools like MS Office, ClickUp, and Canva, and has worked across industries, including higher education and corporate environments.
        </i>
      </p>
    </div>
  </div>
  <!-- Post Single - Author End -->
</template>

<script>
  export default {
    data() {
      return {}
    },
  }
</script>
